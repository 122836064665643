import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import PageBuilder from "../components/pageBuilder/pageBuilder"
import Seo from "../components/seo/seo"
import HeroSelector from "../components/hero/heroSelector"

import TotaraLogo from "../../assets/images/totara.png"
import { findAvailableModule } from "../helpers/findAvailableModule"

import * as styles from "./page.module.scss"

// It's imported this way because it's also used in gatsby-node
const generatePath = require("../helpers/generatePath")

const Page = ({ data, pageContext }) => {
  const { content, seo } = data.sanityPage.tabs
  const translations = data.sanityTranslationPage?.translation ?? data.parentPage?.nodes[0]?.translation
  const enPathUrl = translations && generatePath(translations[0])
  const esPathUrl = translations && generatePath(translations[1])
  // const auPathUrl = translations && generatePath(translations[2])
  // const ptPathUrl = translations && generatePath(translations[3])
  const availableFaq = findAvailableModule(content._rawPageBuilder, "faq", "faqNoImage")

  return (
    <Layout
      theme={content.theme}
      path={generatePath(pageContext.page)}
      translations={translations}
      enPathUrl={enPathUrl}
      esPathUrl={esPathUrl}
      // auPathUrl={auPathUrl}
      // ptPathUrl={ptPathUrl}
    >
      <Seo title={content.title} isBlogPost={false} path={generatePath(pageContext.page)} faq={availableFaq} {...seo} />
      {content.theme === "totara" && <img className={styles.totaraLogo} src={TotaraLogo} alt="Totara" />}
      {content._rawHeroSelector && <HeroSelector heroSelector={content._rawHeroSelector} />}
      {content._rawPageBuilder && <PageBuilder pageBuilder={content._rawPageBuilder} theme={content?.theme} />}
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!, $_id: String!, $market: String!) {
    sanityPage(tabs: { content: { market: { eq: $market }, slug: { current: { eq: $slug } } } }) {
      _id
      tabs {
        content {
          title
          market
          theme
          _rawHeroSelector(resolveReferences: { maxDepth: 10 })
          _rawPageBuilder(resolveReferences: { maxDepth: 10 })
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
    sanityTranslationPage(translation: { elemMatch: { tabs: { content: {} }, _id: { eq: $_id } } }) {
      translation {
        tabs {
          content {
            market
            slug {
              current
            }
            parent {
              tabs {
                content {
                  slug {
                    current
                  }
                }
              }
            }
          }
        }
      }
    }
    parentPage: allSanityTranslationPage(
      filter: {
        translation: {
          elemMatch: { tabs: { content: { parent: { tabs: { content: { slug: { current: { eq: $slug } } } } } } } }
        }
      }
    ) {
      nodes {
        title
        translation {
          tabs {
            content {
              parent {
                tabs {
                  content {
                    slug {
                      current
                    }
                  }
                }
              }
              market
            }
          }
        }
      }
    }
  }
`

export default Page
